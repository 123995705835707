import { getKcContext } from 'keycloakify/login/kcContext/getKcContext'
import { useEffect, useState } from 'react'
import axios from 'axios'

import { Keycloak } from './Keycloak'
import { KcContextCustomTheme, Locales, RealmThemePropertiesResponse } from './types'

export function App() {
  const [kcContext, setkcContext] = useState<KcContextCustomTheme>()
  const [realmThemeProperties, setRealmThemeProperties] = useState<RealmThemePropertiesResponse>()
  const [cssLoaded, setCssLoaded] = useState<boolean>(false)
  const [locales, setLocales] = useState<Locales>()

  // keycloak context
  useEffect(() => {
    const { kcContext } = getKcContext<KcContextCustomTheme>({})
    setkcContext(kcContext)
  }, [])

  // realmThemeProperties
  useEffect(() => {
    if (!kcContext) {
      return
    }
    const url = `${kcContext.properties.customThemeServerUrl}/api/realm/${kcContext.realm.name}`
    getRealmThemeProperties(url)
      .then(setRealmThemeProperties)
      .catch((e) => {
        console.error(e.toString())
      })
  }, [kcContext])

  // css
  useEffect(() => {
    if (!realmThemeProperties) {
      return
    }
    const { css } = realmThemeProperties.theme.sources
    if (!css || css === '') {
      setCssLoaded(true)
      return
    }
    addCSS(css)
    setCssLoaded(true)
  }, [realmThemeProperties])

  // favicon
  useEffect(() => {
    if (!realmThemeProperties) {
      return
    }
    const { favicon } = realmThemeProperties.theme.sources
    if (!favicon || favicon === '') {
      return
    }
    setFavicon(favicon)
  }, [realmThemeProperties])

  // locales
  useEffect(() => {
    if (!realmThemeProperties) {
      return
    }
    const { locales } = realmThemeProperties.theme.sources
    if (!locales || locales === '') {
      setLocales({})
      return
    }
    getLocales(locales)
      .then(setLocales)
      .catch((e) => {
        console.error(e.toString())
      })
  }, [realmThemeProperties])

  if (!kcContext) {
    return null
  }
  if (!realmThemeProperties) {
    return null
  }
  if (!cssLoaded) {
    return null
  }
  if (!locales) {
    return null
  }

  return <Keycloak kcContext={kcContext} locales={locales} />
}

async function getRealmThemeProperties(url: string): Promise<RealmThemePropertiesResponse> {
  console.log(`loading realm theme properties from '${url}'`)
  const http = axios.create({
    headers: {
      'Content-type': 'application/json',
    },
  })
  return new Promise((resolve, reject) => {
    http
      .get<RealmThemePropertiesResponse>(url)
      .then((result) => {
        return resolve(result.data)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}

function addCSS(url: string) {
  console.log(`loading css from '${url}'`)
  const linkElement = document.createElement('link')
  linkElement.setAttribute('rel', 'stylesheet')
  linkElement.setAttribute('type', 'text/css')
  linkElement.setAttribute('href', url)
  document.head.appendChild(linkElement)
}

function setFavicon(url: string) {
  console.log(`loading favicon from '${url}'`)
  const link = document.querySelector("link[rel~='icon']")
  if (link) {
    link.setAttribute('href', url)
  } else {
    const linkElement = document.createElement('link')
    linkElement.setAttribute('rel', 'icon')
    linkElement.setAttribute('href', url)
    document.head.appendChild(linkElement)
  }
}

async function getLocales(url: string): Promise<Locales> {
  console.log(`loading locales from '${url}'`)
  const http = axios.create({
    headers: {
      'Content-type': 'application/json',
    },
  })
  return new Promise((resolve, reject) => {
    http
      .get<Locales>(url)
      .then((result) => {
        return resolve(result.data)
      })
      .catch((e) => {
        return reject(e)
      })
  })
}
