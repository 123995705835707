import { createUseI18n } from "keycloakify/login";

const themeMessages = {
  // NOTE: Here you can override the default i18n messages
  // or define new ones that, for example, you would have
  // defined in the Keycloak admin UI for UserProfile
  // https://user-images.githubusercontent.com/6702424/182050652-522b6fe6-8ee5-49df-aca3-dba2d33f24a5.png
  en: {
    doForgotPassword: 'Forgot password?',
    welcomeTo: '',
    'identity-provider-login-label': 'or sign in with',
    resetPassword: 'Reset password',
    recoverPasswordSuccess: 'The request to recover your password has been sent',
    recoverPasswordEmail: 'Did not receive the email?',
    recoverPasswordSpam: 'Check your spam filter',
    recoverPasswordOr: 'or',
    recoverPasswordTry: 'try another email address',
    recoverBacktoSign: 'Back to sign in',
    doLogin: 'Sign in',
    locale_fo: 'Føroyskt',
  },
  da: {
    /* spell-checker: disable */
    doForgotPassword: 'Glemt kodeord?',
    welcomeTo: '',
    'identity-provider-login-label': 'eller log ind med',
    resetPassword: 'Nulstille kodeord',
    recoverPasswordSuccess: 'Anmodningen om at gendanne din adgangskode er blevet sendt',
    recoverPasswordEmail: 'E-mail ikke modtaget?',
    recoverPasswordSpam: 'Tjek dit spamfilter',
    recoverPasswordOr: 'eller',
    recoverPasswordTry: 'prøv en anden e-mailadresse',
    recoverBacktoSign: 'Tilbage for at logge ind',    
    doLogin: 'Log ind',
    locale_fo: 'Føroyskt',
    /* spell-checker: enable */
  },
  de: {
    /* spell-checker: disable */
    doForgotPassword: 'Passwort vergessen?',
    welcomeTo: '',
    'identity-provider-login-label': 'oder melden Sie sich mit an',
    resetPassword: 'Passwort zurücksetzen',
    recoverPasswordSuccess: 'Die Anfrage zur Wiederherstellung Ihres Passworts wurde gesendet',
    recoverPasswordEmail: 'E-Mail nicht erhalten?',
    recoverPasswordSpam: 'Überprüfen Sie Ihren Spamfilter',
    recoverPasswordOr: 'oder',
    recoverPasswordTry: 'versuchen Sie es mit einer anderen E-Mail-Adresse',
    recoverBacktoSign: 'Zurück zum Anmelden',
    doLogin: 'Einloggen',
    locale_fo: 'Føroyskt',
    /* spell-checker: enable */
  },
  fr: {
    /* spell-checker: disable */
    doForgotPassword: 'Mot de passe oublié ?',
    welcomeTo: '',
    'identity-provider-login-label': 'ou connectez-vous avec',
    resetPassword: 'Réinitialiser le mot de passe',
    recoverPasswordSuccess: 'La demande de récupération de votre mot de passe a été envoyée',
    recoverPasswordEmail: "Vous n'avez pas reçu l'e-mail?",
    recoverPasswordSpam: 'Vérifiez votre filtre anti-spam',
    recoverPasswordOr: 'ou',
    recoverPasswordTry: 'essayez une autre adresse e-mail',
    recoverBacktoSign: 'Retour à la connexion',
    doLogin: 'Connexion',
    locale_fo: 'Føroyskt',
    /* spell-checker: enable */
  },
  nl: {
    /* spell-checker: disable */
    doForgotPassword: 'Wachtwoord vergeten?',
    welcomeTo: '',
    'identity-provider-login-label': 'of log in met',
    resetPassword: 'Wachtwoord opnieuw instellen',
    recoverPasswordSuccess: 'Het verzoek om uw wachtwoord te herstellen is verzonden',
    recoverPasswordEmail: 'Heb je de e-mail niet ontvangen?',
    recoverPasswordSpam: 'Controleer je spamfilter',
    recoverPasswordOr: 'of',
    recoverPasswordTry: 'probeer een ander e-mailadres',
    recoverBacktoSign: 'Terug om in te loggen',
    doLogin: 'Log in',
    locale_fo: 'Føroyskt',
    /* spell-checker: enable */
  },
  sv: {
    /* spell-checker: disable */
    welcomeTo: '',
    doForgotPassword: 'Glömt lösenord?',
    'identity-provider-login-label': 'eller logga in med',
    resetPassword: 'Återställ lösenord',
    recoverPasswordSuccess: 'Begäran om att återställa ditt lösenord har skickats',
    recoverPasswordEmail: 'Fick inte mejlet?',
    recoverPasswordSpam: 'Kontrollera ditt skräppostfilter',
    recoverPasswordOr: 'eller',
    recoverPasswordTry: 'försök med en annan e-postadress',
    recoverBacktoSign: 'Tillbaka för att logga in',
    doLogin: 'Logga in',
    locale_fo: 'Føroyskt',
    /* spell-checker: enable */
  },
}

export const { useI18n } = createUseI18n(themeMessages)

export const messages = themeMessages

export type I18n = NonNullable<ReturnType<typeof useI18n>>;
