import { I18n } from 'keycloakify/login/i18n'
import KcApp from './login/KcApp'
import { createI18n } from './i18n'
import { KcContextCustomTheme, Locales } from './types'

export type Props = {
  kcContext: KcContextCustomTheme
  locales: Locales
}

export function Keycloak({ kcContext, locales }: Props) {
  const i18n = createI18n({ locales }).useI18n({ kcContext })

  if (!i18n) {
    return null
  }

  setTitle(kcContext, i18n)

  return <KcApp {...{ kcContext, overrideI18n: i18n }} />
}

function setTitle(kcContext: KcContextCustomTheme, i18n: I18n) {
  const displayName = kcContext.realm.displayName ? kcContext.realm.displayName : ''
  switch (kcContext.pageId) {
    case 'login.ftl':
    case 'login-otp.ftl':
    case 'login-reset-password.ftl':
      return setDocumentTitle(i18n.msgStr('loginTitle', displayName))
    case 'register.ftl':
      return setDocumentTitle(i18n.msgStr('registerTitle', displayName))
    case 'error.ftl':
      return setDocumentTitle(i18n.msgStr('errorTitle', displayName))
  }
}

function setDocumentTitle(title: string) {
  document.title = title
}
